
/* Import BluePrint styles */
@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";

/* Root level styles */

/*
* Base styling for the page layout is located in the public/index.css
* for consistency when the App is not yet loaded.
* After the app is loaded, we re-add them here to ensure that browsers
* that have cached an old public/index.css version are not affected
* by changes there once inside the app.
*/
@import "../public/index";

/* Global components */

.MOVUS-logo {
  width: auto;
  height: 40px;
}

.table {
  background-color: #fff;
}

.btn-outline-secondary {
  background-color: #fff;
}

.btn-xs {
  padding: 0.25em 0.25em;
  line-height: 1;
}
.btn-xs.btn-outline-secondary.btn-muted {
  border-color: #9da5ad;
}

/* extend Bootstrap */
// an !important margin-bottom can be added here to stop
// buttons from falling behind a chat widget button
// .mb-chat-widget { margin-bottom: 70px !important }

/* Adjust all nav button icons */
.navbar-nav .btn svg {
  vertical-align: -15%;
}

/* Adjust header button icons for all IonIcons under Bootstrap dropdowns */
.dropdown svg {
  width: 1.2em;
  vertical-align: -17.5%;
  margin-right: 0.3em;
}
/* Re-adjust header button icons for all BluePrint Icons under Bootstrap dropdowns */
.dropdown .bp3-icon svg {
  width: initial;
  margin-right: 0;
  display: inline-block;
}

/* Allow object-fit-images to manipulate img objects at or under the following classNames in IE */
img.object-fit-cover, .object-fit-cover img {
  object-fit: cover;
  font-family: 'object-fit: cover;';
}
img.object-fit-contain, .object-fit-contain img {
  object-fit: contain;
  font-family: 'object-fit: contain;';
}
/* IE needs a width and height set to size object-fit images properly, set defaults here */
img.object-fit-cover, .object-fit-cover img,
img.object-fit-contain, .object-fit-contain img {
  height: 100%;
  width: 100%;
}

/* Fonts */

.montserrat {
  font-family: 'Montserrat', sans-serif;
}

/* Cards */

.card-header {
  display: flex;
  justify-content: space-between;
  /*background-color: #007874;*/
  background-color: #fff;
}
.original-card .card-header {
  background-color: rgba(0,0,0,.03);
}

.card-header h1, .card-header h2, .card-header h3, .card-header h4, .card-header h5, .card-header h6 {
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 0;
  text-align: left;
}

/* react-images (modal) fixes */
/* allow modal to be placed over everything */
.react-images__blanket, .react-images__positioner {
  z-index: 9999999 !important;
}
/* allow images to be veritcally centered */
.react-images__view {
  line-height: 100vh !important;
}
/* allow image padding to apply as expected in .react-images__view-image */
.react-images__dialog,
.react-images__frame,
.react-images__pager,
.react-images__track,
.react-images__view-wrapper,
.react-images__view {
  height: 100%;
  width: 100%;
}
/* allow enough spacing to see modal controls */
.react-images__view-image {
  padding: 60px 90px;
}
.fullscreen-enabled .react-images__view-image {
  padding: 0;
}

/* Bootstrap fixes */

.container-in-container {
  padding-left: 0;
  padding-right: 0;
}

/* small-gutters works like normal bootstrap gutters but smaller */
.small-gutters {
  margin-left: -0.15rem;
  margin-right: -0.15rem;
}
.small-gutters>.col, .small-gutters>[class*=col-] {
  padding-left: 0.15rem;
  padding-right: 0.15rem;
}

/*
* react-icon fixes:
* adjust react-icon vertical alignment heights which are a bit off
* https://github.com/react-icons/react-icons/issues/222#issuecomment-473507367
*/

.react-icon--inline {
  line-height: 1;
  vertical-align: -25%;
}

/*
* chat widget fix
* Ensure all modals have enough space at the bottom
* so that the chat widget doesn't cover any call to actions
*/
.modal, .modal-backdrop {
  z-index: 999999;
}

/*
* And fix tooltips to appear above modals
*/
.tooltip {
  z-index: 1000000;
}

/*
* And fix toasts to appear above tooltips
*/
.toaster {
  z-index: 1000001;
}

/*
* Print view
*/
@media print {
  /* to mock print view without background colours by uncommenting the following style */
  #root * {
    /* background: none; */
  }
  /* add stroke to svgs as icons may be white on white background */
  svg {
    stroke: #000;
    stroke-width: 1%;
  }
  /* adjust stroke width for badges, as they are quite tiny */
  .badge svg {
    stroke-width: 5%;
  }
  /* add stroke to text buttons that might be white on white background */
  .btn {
    text-shadow:
    -0.5px -0.5px 0 #999,
    0.5px -0.5px 0 #999,
    -0.5px 0.5px 0 #999,
    0.5px 0.5px 0 #999 !important;
  }
}

.bg-movus-green {
  background-color: rgb(0, 175,170);
}
.color-movus-green {
  color: rgb(0, 175,170);
}

.carousel-control-prev-icon {
  position: absolute;
  bottom: -50px;
  left: 40px;
}

.carousel-control-next-icon {
  position: absolute;
  bottom: -50px;
  right: 40px;
}

.carousel-control-prev {
  width: 0;
}

.carousel-control-next {
  width: 0;
}

.possible-causes-modal-content {
  height: 480px;
}
